@import '_vars';

@font-face {
  font-family: 'Bebas Neue';
  src: url('./assets/BebasNeue-Bold.otf');
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: $desktop-font-size;
  @media (max-width: $desktop-width) {
    font-size: #{$desktop-font-size/$desktop-width*100}vw;
  }
  @media (max-width: $tablet-width) {
    font-size: #{$tablet-font-size/$tablet-width*100}vw;
  }
  @media (max-width: $mobile-width) {
    font-size: #{$mobile-font-size/$mobile-width*100}vw;
  }
}

html, body, #root, .app {
  width: 100%;
  margin: 0 auto;
}

body {
  background: #000;
  font-family: 'Playfair Display';
  color: #e8e3d9;
  font-size: 16rem;
}

p, h1, h2, h3, h4, h5 {
  margin: 0;
}

.app {
  // max-width: $desktop-width;
}