@import '../_vars';

.share-dropdown {
  position: absolute;
  right: 0;
  top: 53rem;
  background: #000;
  transition: all 0.4s ease;
  font-style: italic;
  color: #aa8a5c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 5rem 0 15rem;

  @media (max-width: $mobile-width) {
    position: relative;
    top: 0;
    text-align: right;
    justify-content: flex-end;
    padding: 0;
    transition: all 0.2s ease;
  }

  &[data-expanded="false"] {
    opacity: 0;
    z-index: -1;
    top: -160rem;

    @media (max-width: $mobile-width) {
      display: none;
    }
    
    .social-share {
      opacity: 0;
    }
  }

  &[data-expanded="true"] {
    opacity: 1;
    top: 53rem;
    z-index: 1;
    
    @media (max-width: $mobile-width) {
      top: 0;
      display: block;
    }

    .social-share {
      opacity: 1;
    }
  }

  .social-share {
    color: #aa8a5c;
    text-decoration: none;
    padding: 10rem 60rem;
    transition: all 0.3s ease;

    @media (max-width: $mobile-width) {
      padding: 10rem 0;
    }

    &:hover {
      color: #e4b777;
      cursor: pointer;
    }

    &:active, &:focus {
      outline: none;
    }
  }
}

#copyLink {
  display: none;
}