@import '../_vars';

.modal {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 900rem;
  max-width: 100%;
  
  @media (max-width: $mobile-width) {
    flex-direction: column;
    justify-content: center;
  }
  .focus-marker {
    position: absolute;
    width: 0;
    height: 0;
    visibility: hidden;
    top: calc(-50vh + 50%);
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 40rem;
    padding: 10rem;
    z-index: 1;
    cursor: pointer;
  }
  h3 {
    font-size: 33rem;
    font-family: "Bebas Neue";
  }
  p {
    font-size: 16rem;
    line-height: 1.4;
    margin: 10px 0;
    
    @media (max-width: $mobile-width) {
      font-size: 19rem;
      margin: 0;
    }
  }
  video {
    width: 100%;
    height: 100%;
  }
  .image {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  .plyr__control {
    box-shadow: none;

    &:hover {
      background: none;
    }
  }

  &[data-type="content-photo"] {
    height: 600rem;
    padding: 50rem;
    max-height: 100%;
    .col {
      width: calc(50% - 20rem);
      padding-right: 20rem;
      &:not(:last-of-type) {
        padding-right: 0;
      }
    }
    @media (max-width: $mobile-width) {
      justify-content: center;
      flex-direction: column;
      height: 100%;
      .col {
        height: calc(50vh - 50rem);
        height: calc(var(--vh, 1vh) * 50 - 50rem);
        width: 100%;
        &:not(:last-of-type) {
          margin-bottom: 30rem;
        }
      }
    }
  }

  &[data-type="experience-video"], &[data-type="experience-photo"] {
    &[data-side="right"] {
      @media (min-width: 1200px) {
        margin-left: 400rem;
      }

      @media (min-width: #{$mobile-width + 1}) {
        margin-left: 300rem;
      }
    }

    &[data-side="left"] {
      @media (min-width: #{$mobile-width + 1}) {
        margin-right: 400rem;
      }
    }
  }

  &[data-type="experience-photo"], &[data-type="experience-video"]  {
    max-height: calc(100vh - 127rem);
    max-height: calc(var(--vh, 1vh) * 100 - 127rem);

    @media (min-width: 1200px) {
      width: 600rem;
    }

    @media (min-width: #{$mobile-width + 1}) {
      width: 700rem;
    }

    .close {
      top: -10rem;
      right: -50rem;
    }

    .col {
      position: relative;
      width: 100%;
      &:first-of-type {
        height: calc(60% - 17rem);
      }
      &:last-of-type {
        margin-left: 30rem;
        height: calc(40% - 17rem);
        padding-right: 7px;
      }
    }
    
    @media (max-width: $mobile-width) {
      padding: 50rem;
      height: calc(100vh - 127rem);
      height: calc(var(--vh, 1vh) * 100 - 127rem);
      flex-direction: column;
      max-height: 900rem;
      .close {
        top: 50rem;
        right: 10rem;
      }
      .col {
        height: calc(40% - 17rem);
        &:last-of-type {
          max-height: none;
          margin-left: 0;
          margin-top: 30rem;
        }
      }
    }
  }

  &[data-type="experience-video"] {
    flex-direction: column;
    width: 600rem;
    .close {
      top: -8rem;
      right: 0rem;
    }
    .col {
      padding: 0 50rem;
      &:first-of-type {
        // height: auto;
      }
      &:last-of-type {
        margin: 20rem 0;
        padding-right: 50rem;
      }
    }

    @media (max-width: $mobile-width) {
      .close {
        top: 65rem;
        right: 0rem;
      }
      .col {
        padding: 0;
        &:last-of-type {
          margin-top: 60rem;
          padding: 0 15rem 0 0;
        }
      }
    }
  }  
}


.plyr__video-embed iframe {
  z-index: 10;
}

.plyr__control--overlaid {
  opacity: 0;
  padding: 15px 28px;
  &:hover {
    cursor: pointer;
  }
}

.plyr__control {
  &:hover {
    cursor: pointer;
  }
}

// Override Scrollbar Css
.scollarea-content {
  margin: 0 !important;
}
.scrollbar-container {
  background: #acabaa !important;
  opacity: 1 !important;
  width: 4px !important;
  border-radius: 10px !important;
  // margin-left: 5px !important;
}

.scrollbar {
  background: #a78c60 !important;
  width: 4px !important;
  border-radius: 10px !important;
  margin-left: 0 !important;
}
