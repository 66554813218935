.app {
  overflow-x: hidden;
  .nav-down {
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 1;
    svg {
      cursor: pointer;
      width: 53rem;
      height: 53rem;
    }
  }
}