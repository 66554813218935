@import '../_vars';

.footer {
  position: relative;
  font-family: 'Open Sans', sans-serif;
  height: 74rem;
  .logo {
    position: absolute;
    top: 50%;
    left: 50rem;
    height: 35%;
    transform: translateY(-50%);
    
    @media (max-width: $mobile-width) {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .items {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    @media (max-width: $tablet-width) {
      padding-left: 110rem;
    }
  }
  .item {
    padding: 0 15rem;
    height: 74rem;
    line-height: 74rem;
    cursor: pointer;
    color: #e8e3d9;
    text-decoration: none;
    
    &:hover {
      background: rgba(232, 227, 217, 0.2);
    }
    .label {
      font-size: 16rem;
      display: inline-block;
      vertical-align: middle;
    }
  }
}