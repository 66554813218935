@import '../_vars';

.content {
  position: relative;
  margin: 0;
  padding-bottom: 340rem;
  line-height: 1.4;
  background: linear-gradient(180deg, rgba(0,0,0,1) 75%, rgba(38,31,21,1) 100%);
  .content-items {
    display: flex;
    flex-wrap: wrap;
    max-width: $desktop-width;
  }
  .inner {
    position: relative;
    width: 100vw;
    &:hover {
      cursor: pointer;
      .inner-content {
        opacity: 0;
      }
    }
  }
  .content-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    @media (max-width: $mobile-width) {
      position: fixed;
      z-index: 1;
    }
  }
  .content-box {
    width: 100%;
    .image {
      background-position: 0 -180px;
      background-size: cover;
      width: 100%;
      height: 278rem;
      
      @media (max-width: $mobile-width) {
        height: 180rem;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    .inner-content {
      position: absolute;
      top: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.65);
      width: 100%;
      height: 100%;
      transition: all 0.2s ease;
    }
    .inner-heading {
      font-size: 35rem;
      font-style: oblique;
      position: absolute;
      color: #aa8a5c;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .inner-description {
      font-size: 14rem;
    }
    // @media (max-width: $tablet-width) {
    //   width: 50%;
    //   .image {
    //     height: 321rem;
    //   }
    // }
    @media (max-width: $mobile-width) {
      width: 100%;
    }
  }
}