@import "../_vars";

.experience {
  position: relative;
  width: 100%;
  height: 100vh;
  height: calc(100vh - 106rem);
  height: calc(var(--vh, 1vh) * 100 - 106rem);
  &[data-hidden] {
    .overlay {
      opacity: 0;
      visibility: hidden;
    }
  }
  &[data-visible] {
    min-height: 350rem;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s visibility, 0.3s opacity;
  }
  .overlay-contents {
    max-width: 300rem;
    text-align: center;
    h2 {
      font-family: "Bebas Neue";
      font-size: 90rem;
      margin-bottom: -20rem;
      color: #fff;
    }
    h3 {
      font-size: 42rem;
      font-style: italic;
      margin-bottom: 20rem;
      color: #aa8a5c;
    }
    p {
      font-size: 16rem;
      margin-bottom: 35rem;
      @media (max-width: $mobile-width) {
        font-size: 20rem;
      }

      &.nav-instructions {
        margin: -15rem 0 15rem;
      }
    }
    .btn {
      margin: 10px auto 0;
      font-family: "Bebas Neue";
      font-size: 22rem;
      background: rgba(0, 0, 0, 0.5);
      width: 150rem;
      padding-top: 10rem;
      padding-bottom: 6rem;
      cursor: pointer;
      &:hover {
        background: #aa8a5c;
        color: #fff;
      }
    }
  }
  .modal-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @media (max-width: $mobile-width) {
      position: fixed;
      z-index: 1;
    }
  }
}

$circle-width: 18px;
%content {
  content: ' ';
  width: $circle-width / 2;
  height: 2px;
  background: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.zoom {
  position: absolute;
  bottom: $circle-width;
  left: $circle-width;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 29rem;
  height: 56rem;
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
  transition: all 0.2s ease;
  
  &.hide {
    opacity: 0;
  }
  
  @media (max-width: $mobile-width) {
    bottom: 10px;
    left: 10px;
    width: 40rem;
    height: 75rem;

  }

  span {
    color: #000;
    width: $circle-width;
    height: $circle-width;
    border: 2px solid #000;
    border-radius: $circle-width / 2;
    position: relative;
    margin: 2px 0;

    &:hover {
      cursor: pointer;
    }

    &#zoom-in {
      &:before, &:after {
        @extend %content;
      }
      
      &:after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    &#zoom-out {
      &:before {
        @extend %content;
      }
    }
  }
}

video {
  margin: 30px auto;
  width: 70%;
  display: block;

  @media (max-width: $mobile-width) {
    margin: 15px auto;
  }
}