.deep-zoom {
  width: 100%;
  height: 100%;
  background: black;
  &[data-overlay] {
    opacity: 0.2;
  }

  &:hover {
    cursor: grab;
  }

  &[data-hovering="true"] {
    cursor: pointer;
  }

  &[data-grabbing="true"] {
    cursor: grabbing;
  }
}