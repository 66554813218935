$circle: 6.5px;

.mobile-menu {
  .hamburger {
    position: absolute;
    right: 35rem;
    top: 50%;
    transform: translateY(-50%);
    flex-direction: row;
    display: flex;
    z-index: 2;
    
    &:hover {
      cursor: pointer;
    }

    .dot {
      height: $circle;
      width: $circle;
      border-radius: calc(#{$circle} / 2);
      background: #fff;
      margin: 2px 10px;

      &:before, &:after {
        content: '';
        height: $circle;
        width: $circle;
        border-radius: calc(#{$circle} / 2);
        background: #fff;
        margin-right: 10px;
      }
  
      &:before {
        position: absolute;
        left: -3px;
      }
  
      &:after {
        position: absolute;
        right: -13px;
      }
    }
    
  }

  .expanded-menu {
    position: absolute;
    right: 0;
    width: 50%;
    text-align: right;
    background: rgba(0, 0, 0, .95);
    height: 100vh;
    z-index: 1;
    padding: 80rem 0;
    transition: all 0.4s ease;
    
    &[data-expanded="false"] {
      transform: translateX(600px);
    }
    &[data-expanded="true"] {
      transform: translateX(0);
    }

    .link-container {
      display: flex;
      flex-direction: column;
      padding: 35rem;
    }

    a, span {
      text-decoration: none;
      color: #e8e3d9;
      font-family: 'Playfair Display';
      font-weight: 700;
      font-size: 25rem;
      margin: 12px 0;

      &:hover {
        cursor: pointer;
      }
    }

    .border {
      height: 3px;
      width: 100%;
      background: #cccccc;
    }
  }
}

@keyframes menuAnimation {
  0% {
    transform: translateX(600px);
  }
  100% {
    transform: translateX(0px);
  }
}