@import '../_vars';

.header {
  position: relative;
  height: 53rem;
  @media (max-width: $mobile-width) {
    height: 58rem;
  }
  .items {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    flex-direction: row;
    display: flex;
    padding-right: 30rem;
  }
  .logo {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50rem;
    height: 30rem;
    @media (max-width: $mobile-width) {
      height: 37rem;
    }
  }
}

.header-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  padding: 10rem 30rem;
  font-weight: 700;
  position: relative;
  z-index: 5;
  cursor: pointer;
  white-space: nowrap;
  
  &:hover {
    z-index: 5;
    background: rgba(232, 227, 217, 0.2);
  }

  .header-label {
    display: inline-block;
    vertical-align: middle;
    font-style: italic;
    font-size: 13px;
    color: #e8e3d9;
    text-decoration: none;
  }
  .header-image {
    vertical-align: middle;
    margin-right: 10rem;
    height: 80%;
  }
}